import { listado } from "./listado";
import { api } from "./backend";
import { parseDate } from './date'

export function crear() {
  return {
    id: 0,
    indicadorId: "",
    fecha: new Date(),
    valor: 0,
    zona: "",
    causa: "",
    valorRezagado: "",
  };
}

export async function listar(indicadorId, params) {
  params.filtros = { indicadorId };
  return await listado("simulacion/listar", params);
}

export async function listarParaRezagado(params) {
  return await listado("simulacion/listarParaRezagado", params);
}

export async function cargar(id) {
  const response = await api.get("simulacion/cargar", { id });
  const row = response.data;

  row.fecha = parseDate(row.fecha);
  row.creadaEl = parseDate(row.creadaEl);

  return row;
}

export async function insertar(simulacion) {
  const response = await api.post("simulacion/insertar", { simulacion });
  return response.data;
}

export async function actualizar(simulacion) {
  const response = await api.post("simulacion/actualizar", { simulacion });
  return response.data;
}

export async function eliminar(id) {
  await api.post("simulacion/eliminar", { id });
}
