<template>
  <b-form @submit.prevent="submit" autocomplete="off" class="pl-3 pr-3 col col-sm-12">
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-form-group :label="$t('Fecha')">
          <b-form-datepicker
            required
            v-model="simulacion.fecha"
            :placeholder="$t('Selecciona una fecha')"
            :date-format-options="$dateFormatOptions"
            class="mb-2"
            locale="es"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Valor previsto">
          <b-form-input required type="text" v-model="simulacion.valor"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Valor rezagado previsto">
          <b-form-input
            required
            type="text"
            :value="simulacion.valorRezagado"
            placeholder="(no calculado)"
            readonly
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="$t('Zona')">
          <b-form-input
            type="text"
            :value="simulacion.zona"
            placeholder="(ninguna)"
            aria-describedby="zona-help-block"
            readonly
          ></b-form-input>
          <b-form-text id="zona-help-block">
            La zona se puede seleccionar desde el Dashboard. El sistema permite diferenciar simulaciones indicadas para
            una zona o para todas las zonas. Esto se determina utilizando el filtro de zonas en el Dashboard.
          </b-form-text>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group :label="$t('Observaciones')">
          <b-form-textarea v-model="simulacion.observaciones" rows="3" max-rows="6"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <botonera-edicion :url-cancelar="enlaceVuelta"> </botonera-edicion>
  </b-form>
</template>
<script>
import Vue from "vue";

export default {
  props: {
    simulacion: Object,
  },
  computed: {
    enlaceVuelta() {
      const params = new URLSearchParams(this.$route.query).toString();
      return `/simulaciones?${params}`;
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
