<template>
  <page fluid="lg" :title="title">

    <b-alert show>
      La última medición del <b-badge>indicador: {{ adelantado.nombre }}</b-badge> es del día <b>{{ ultimaFecha | fecha }}</b>
    </b-alert>

    <formulario :simulacion="simulacion" @submit="insertar"></formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiSimulaciones from "@/services/simulaciones.js";
import * as apiIndicadores from "@/services/indicadores.js";

export default {
  components: { Formulario },
  computed: {
    title() {
      return `${this.adelantado.nombre}: Crear simulación para ${this.rezagado.nombre}`;
    },
  },
  data() {
    const simulacion = apiSimulaciones.crear();
    const qs = this.$route.query;

    if (qs.zona) {
      simulacion.zona = qs.zona;
    }

    return {
      adelantado: {},
      rezagado: {},
      simulacion: simulacion,
      ultimaFecha: ""
    };
  },
  methods: {
    async insertar() {
      this.simulacion.rezagadoId = this.rezagado.id;
      this.simulacion.adelantadoId = this.adelantado.id;
      let ok = await apiSimulaciones.insertar(this.simulacion);
      if (ok) {
        this.$emit("userMessage", "Se ha creado la simulación");
        const params = new URLSearchParams(this.$route.query).toString();
        const url = `/simulaciones?${params}`;
        this.$router.push(url);
      }
    },
    async cargar() {
      const qs = this.$route.query;
      const rs = await Promise.all([
        apiIndicadores.cargar(qs.adelantadoId),
        apiIndicadores.cargar(qs.rezagadoId),
        apiIndicadores.cargarUltimaFecha(qs.adelantadoId)
      ]);
      this.adelantado = rs[0];
      this.rezagado = rs[1];
      this.ultimaFecha = rs[2];
    },
  },
  mounted() {
    this.cargar();
  },
};
</script>
